import copy from 'copy-to-clipboard';
import type { ReactNode } from 'react';

import { IconButton, Pill, type TableColumn } from '@zen/DesignSystem';

import type { AccountConnectionsSummary } from '../types';
import DeleteConnectionButton from './DeleteConnectionButton';
import { connectionTypeMapping, statusMapping } from './helpers';

const getColumnsConfiguration = (accountId: string): TableColumn<AccountConnectionsSummary>[] => {
  return [
    {
      key: 'company',
      title: 'Company',
      render: (_, { account }: AccountConnectionsSummary): ReactNode => {
        return <div>{account.tradingName}</div>;
      },
      sortable: false
    },
    {
      key: 'connectionType',
      title: 'Type',
      render: (_, { connectionType }: AccountConnectionsSummary): ReactNode => {
        return connectionTypeMapping[connectionType];
      },
      sortable: false
    },
    {
      key: 'status',
      title: '',
      render: (_, { account }: AccountConnectionsSummary): ReactNode => {
        const { label, pillType } = statusMapping[account.status];

        return <Pill type={pillType}>{label}</Pill>;
      },
      sortable: false,
      width: '10%'
    },
    {
      key: 'actions',
      fixed: 'right',
      render: (_, { account, connectionType }: AccountConnectionsSummary): ReactNode => {
        const { id, tradingName } = account;

        return (
          <div className="flex space-x-2">
            <IconButton icon="zicon-copy" onClick={() => copy(id)} size="medium" title="Copy account ID" variant="ghost" />
            <DeleteConnectionButton
              connectionType={connectionType}
              sourceAccountId={accountId}
              targetAccountId={id}
              targetAccountName={tradingName}
            />
          </div>
        );
      },
      sortable: false
    }
  ];
};

export default getColumnsConfiguration;
